// import React, { useState } from "react"
import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import Fade from "react-reveal/Fade"
// import Modal from "./Modal"
// import OpenModal from "./OpenModal"

const ServiceItem = ({
  serviceName,
  serviceImage,
  serviceNum,
  serviceSlug,
  serviceContent,
}) => {
  // const [isModal, setIsModal] = useState(false)

  return (
    <>
      {/* <OpenModal
        className="services__item"
        setIsModal={setIsModal}
        slug={serviceSlug}
      > */}
      <div className="services__item">
        <Fade bottom duration={400} delay={serviceNum} distance={"20px"}>
          <figure className="services__item__image" role="figure">
            <Img fluid={serviceImage} alt={serviceName} />
            <figcaption className="services__item__caption">
              {serviceName}
            </figcaption>
          </figure>
        </Fade>
      </div>
      {/* </OpenModal>

      {isModal && (
        <Modal
          title={serviceName}
          content={serviceContent}
          setIsModal={setIsModal}
        />
      )} */}
    </>
  )
}

export default ServiceItem
