import React, { useEffect } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const Hero = () => {
  useEffect(() => {
    const currentWidth = window.innerWidth
    const heroBG = document.querySelector(".hero")
    const windowHeight = window.innerHeight
    const width = window.matchMedia("(max-width: 668px)")
    if (width.matches) {
      // If media query matches
      heroBG.style.height = windowHeight - 30 + "px"
    } else {
      heroBG.style.height = windowHeight + "px"
    }

    window.addEventListener("resize", function () {
      if (currentWidth !== window.innerWidth) {
        const windowHeight = window.innerHeight
        heroBG.style.height = windowHeight + "px"
      }
    })
  })

  const data = useStaticQuery(graphql`
    query {
      heroSmallImage1: file(relativePath: { eq: "hero-img1-small.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 800) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      heroMediumImage1: file(relativePath: { eq: "hero-img1-medium.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      heroSmallImage2: file(relativePath: { eq: "hero-img2-small.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 700) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      heroMediumImage2: file(relativePath: { eq: "hero-img2-medium.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      heroSmallImage3: file(relativePath: { eq: "hero-img3-small.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 700) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      heroMediumImage3: file(relativePath: { eq: "hero-img3-medium.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      heroSmallImage4: file(relativePath: { eq: "hero-img4-small.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 700) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      heroMediumImage4: file(relativePath: { eq: "hero-img4-medium.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const heroImage1 = [
    data.heroSmallImage1.childImageSharp.fluid,
    {
      ...data.heroMediumImage1.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  const heroImage2 = [
    data.heroSmallImage2.childImageSharp.fluid,
    {
      ...data.heroMediumImage2.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  const heroImage3 = [
    data.heroSmallImage3.childImageSharp.fluid,
    {
      ...data.heroMediumImage3.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  const heroImage4 = [
    data.heroSmallImage4.childImageSharp.fluid,
    {
      ...data.heroMediumImage4.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const settings = {
    dots: true,
    pauseOnHover: false,
    fade: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <>
      <div className="hero">
        <Slider {...settings}>
          <Link to="#works">
            <BackgroundImage
              className="hero__bg"
              objectFit="cover"
              fluid={heroImage1}
            />
            <div className="hero__copy">
              <div className="hero__copy--inner container-wide">
                <div className="hero__copy--title">
                  <span>Narrative Films</span>
                </div>
                <div className="hero__copy--film">
                  Ainu Mosir
                  <br />
                  <span className="year">2020</span>
                </div>
              </div>
            </div>
          </Link>

          <Link to="#works">
            <BackgroundImage
              className="hero__bg"
              objectFit="cover"
              fluid={heroImage2}
            />
            <div className="hero__copy">
              <div className="hero__copy--inner container-wide">
                <div className="hero__copy--title">
                  <span>Documentaries</span>
                </div>
                <div className="hero__copy--film">
                  Koshien: Japan's Field of Dreams
                  <br />
                  <span className="year">2019</span>
                </div>
              </div>
            </div>
          </Link>

          <Link to="#services">
            <BackgroundImage
              className="hero__bg"
              objectFit="cover"
              fluid={heroImage3}
            />
            <div className="hero__copy">
              <div className="hero__copy--inner container-wide">
                <div className="hero__copy--title">
                  <span>Services</span>
                </div>
                <div className="hero__copy--film">
                  Ryuichi Sakamoto | Opus
                  <br />
                  <span className="year">2023</span>
                </div>
              </div>
            </div>
          </Link>

          <Link to="#restoration">
            <BackgroundImage
              className="hero__bg"
              objectFit="cover"
              fluid={heroImage4}
            />
            <div className="hero__copy">
              <div className="hero__copy--inner container-wide">
                <div className="hero__copy--title">
                  <span>Restoration</span>
                </div>
                <div className="hero__copy--film">
                  UGETSU
                  <br />
                  <span className="year">1953</span>
                </div>
              </div>
            </div>
          </Link>
        </Slider>
      </div>
    </>
  )
}

export default Hero
