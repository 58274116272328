import React, { useState } from "react"
import Img from "gatsby-image/withIEPolyfill"
import Fade from "react-reveal/Fade"
import Modal from "./Modal"
import OpenModal from "./OpenModal"

const TeamItem = ({ slug, image, name, title, num, detail }) => {
  const [isModal, setIsModal] = useState(false)

  return (
    <>
      <OpenModal setIsModal={setIsModal} slug={slug} className="team__list">
        <Fade bottom duration={400} delay={num * 100 + 300} distance={"20px"}>
          <Img fluid={image} alt="" className="team__list__image" />
          <div className="team__list__text">
            <h4 className="team__list__text--name">{name}</h4>
            <div className="team__list__text--title">{title}</div>
          </div>
        </Fade>
      </OpenModal>

      {isModal && (
        <Modal
          title={name}
          slug="team"
          sub={title}
          image={image}
          content={detail}
          setIsModal={setIsModal}
        />
      )}
    </>
  )
}

export default TeamItem
