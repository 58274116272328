import React from "react"
import Map from "./Map"
import Fade from "react-reveal/Fade"
import Loadable from "@loadable/component"

const LoadableMap = Loadable(() => import("./Map"))

const Location = () => {
  const newyork = {
    center: {
      lat: 40.75993,
      lng: -73.9912808,
    },
    zoom: 12,
    styles: [
      {
        stylers: [
          {
            saturation: -100,
          },
        ],
      },
    ],
  }
  const tokyo = {
    center: {
      lat: 35.72312152664697,
      lng: 139.7643504934311,
    },
    zoom: 11,
  }

  return (
    <div className="location__items">
      <div className="location__item">
        <Fade bottom distance={"20px"} duration={400} delay={300}>
          <LoadableMap location={newyork} />
          {/* <Map location={newyork} /> */}
          <div className="location__item__text">
            <div className="location__item__text--location">
              Cineric Creative New York
            </div>
            <div className="location__item__text--address">
              630 Ninth Avenue Suite 508, New York, NY 10036
            </div>
            <div className="location__item__text--link">
              <span className="ico-ar"></span>
              <a
                href="https://www.google.co.jp/maps/place/Cineric+Inc/@40.7573192,-74.0532906,12z/data=!4m5!3m4!1s0x89c25853c9cfe7d3:0x882effaec6560064!8m2!3d40.7599157!4d-73.9911492"
                target="_blank"
                rel="noopener noreferrer"
                className="link-color link-ul"
              >
                Google Maps
              </a>
            </div>
          </div>
        </Fade>
      </div>
      <div className="location__item">
        <Fade bottom distance={"20px"} duration={400} delay={400}>
          <Map location={tokyo} />
          <div className="location__item__text">
            <div className="location__item__text--location">
              Cineric Creative Tokyo
            </div>
            <div className="location__item__text--address">
              2-33-1 Shichiya House A-201 Nezu, Bunkyo-ku, Tokyo, 113-0031
            </div>
            <div className="location__item__text--link">
              <span className="ico-ar"></span>
              <a
                href="https://maps.app.goo.gl/fSzmQC94QB18dowZ8"
                target="_blank"
                rel="noopener noreferrer"
                className=" link-color link-ul"
              >
                Google Maps
              </a>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Location
