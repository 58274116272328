import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import GoogleMapReact from "google-map-react"

const Map = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      iconPin: file(relativePath: { eq: "ico-map.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const Marker = () => {
    return (
      <Img
        className="ico-pin"
        fluid={data.iconPin.childImageSharp.fluid}
        alt="Cineric Creative"
      />
    )
  }

  return (
    <>
      <div className="map">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyD7dUJcAc6kdhYyAmDu0Q5iuh0EVBmxrzQ",
          }}
          defaultCenter={location.center}
          defaultZoom={location.zoom}
        >
          <Marker lat={location.center.lat} lng={location.center.lng} />
        </GoogleMapReact>
      </div>
    </>
  )
}

export default Map
