import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import Loadable from "@loadable/component"

import Modal from "./Modal"
import OpenModal from "./OpenModal"

const LoadableWorksImage = Loadable(() => import("./WorksImage"))

const WorksItem = ({
  worksName,
  worksYear,
  worksDirector,
  worksImage,
  worksNum,
  worksSlug,
  worksContent,
}) => {
  const [isModal, setIsModal] = useState(false)

  return (
    <>
      <OpenModal
        setIsModal={setIsModal}
        slug={worksSlug}
        className="works__item"
      >
        <figure className="works__item__image" role="figure">
          <Fade bottom duration={400} delay={worksNum} distance={"20px"}>
            <div>
              <LoadableWorksImage
                worksImage={worksImage}
                worksName={worksName}
              />
              <figcaption className="works__item__caption">
                <div>
                  <p className="works__item__caption--name">{worksName}</p>
                  <p className="works__item__caption--director">
                    {worksDirector} {worksYear}
                  </p>
                </div>
              </figcaption>
            </div>
          </Fade>
        </figure>
      </OpenModal>

      {isModal && (
        <Modal
          slug={worksSlug}
          title={`${worksName} (${worksYear})`}
          content={worksContent}
          setIsModal={setIsModal}
        />
      )}
    </>
  )
}

export default WorksItem
