import React from "react"

const OpenModal = ({ children, className, slug, setIsModal }) => {
  function handleOpen() {
    // Check body width & hide header
    const header = document.querySelector(".header")
    const clientWidth = document.body.clientWidth
    header.style.opacity = 0
    // Add overflow:hidden & check scrollbar width & add padding-right to body & add modal element
    setTimeout(() => {
      document.body.classList.add("lock")
      const noScrollBarWidth = document.body.clientWidth
      const diff = noScrollBarWidth - clientWidth
      if (diff > 0) {
        document.body.style.paddingRight = diff + "px"
      }
      setIsModal(true)
      // Add fadein animation
      setTimeout(() => {
        const modal = document.querySelector(".modal__overlay")
        modal.classList.add("show")
      }, 100)
    }, 100)
  }

  return (
    <button
      className={`${className} ${className}--${slug}`}
      onClick={handleOpen}
    >
      {children}
    </button>
  )
}

export default OpenModal
