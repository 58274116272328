import React from "react"
import Img from "gatsby-image/withIEPolyfill"

const Modal = ({ title, image, sub, content, setIsModal, slug }) => {
  const handleClose = () => {
    const header = document.querySelector(".header")
    const modal = document.querySelector(".modal__overlay")
    modal.classList.remove("show")
    setTimeout(() => {
      document.body.style.removeProperty("padding-right")
      header.style.removeProperty("opacity")
      document.body.classList.remove("lock")
      setIsModal(false)
    }, 200)
  }
  function preventClose(e) {
    e.stopPropagation()
  }

  return (
    <>
      {/* eslint-disable-next-line */}
      <div
        className={`modal__overlay ${slug ? slug : ""}`}
        onClick={handleClose}
        onKeyDown={handleClose}
      >
        {/* eslint-disable-next-line */}
        <div
          className="modal__inner"
          onClick={preventClose}
          onKeyDown={preventClose}
        >
          {image && <Img fluid={image} alt={title} className="modal__image" />}
          <div className="modal__inner__box">
            <h2 className="modal__header">{title}</h2>
            {sub && <div className="modal__sub">{sub}</div>}
            <div className="modal__content">
              {content}

              <button
                className="close__fixed"
                onClick={handleClose}
                onKeyDown={handleClose}
              >
                <span className="line line--top"></span>
                <span className="line line--bottom"></span>
                <span className="text">CLOSE</span>
              </button>
              <button
                onClick={handleClose}
                onKeyDown={handleClose}
                className="close__button"
                tabIndex={-1}
              >
                <span className="close__button__icon">×</span>
                <span className="close__button__text">CLOSE</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal
