import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ServicesItem from "./ServicesItem"

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      imageBrandSmall: file(
        relativePath: { eq: "services-item-brand-small.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageBrandMedium: file(
        relativePath: { eq: "services-item-brand-medium.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 620) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imagePostSmall: file(
        relativePath: { eq: "services-item-post-small.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imagePostMedium: file(
        relativePath: { eq: "services-item-post-medium.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 620) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const imageBrand = [
    data.imageBrandSmall.childImageSharp.fluid,
    {
      ...data.imageBrandMedium.childImageSharp.fluid,
      media: `(min-width: 668px)`,
    },
  ]
  const imagePost = [
    data.imagePostSmall.childImageSharp.fluid,
    {
      ...data.imagePostMedium.childImageSharp.fluid,
      media: `(min-width: 668px)`,
    },
  ]

  const num = []
  for (let i = 0; i < 2; ++i) {
    num.push(i * 100)
  }

  return (
    <>
      <div className="services__items">
        <ServicesItem
          serviceName="Branded Content"
          serviceImage={imageBrand}
          serviceNum={num[0]}
          serviceSlug="brand"
          serviceContent="description text"
        />
        <ServicesItem
          serviceName="Post Production"
          serviceImage={imagePost}
          serviceNum={num[1]}
          serviceSlug="post"
          serviceContent="description text"
        />
      </div>
    </>
  )
}

export default Services
