import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Fade from "react-reveal/Fade"

const Slick = () => {
  const data = useStaticQuery(graphql`
    query {
      restorationImage1: file(relativePath: { eq: "restoration-image1.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      restorationImage2: file(relativePath: { eq: "restoration-image2.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      restorationImage3: file(relativePath: { eq: "restoration-image3.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className="restoration__content__image">
      <Fade bottom distance={"20px"} duration={400} delay={300}>
        <div className="restoration__content__items">
          <div className="restoration__content__item">
            <Img
              fluid={data.restorationImage1.childImageSharp.fluid}
              alt="Sansho the Bailiff"
              className="restoration__content__item__image"
            />
            <div className="restoration__content__item__text">
              Sansho the Bailiff 1954
            </div>
          </div>
          <div className="restoration__content__item">
            <Img
              fluid={data.restorationImage2.childImageSharp.fluid}
              alt="The King and I"
            />
            <div className="restoration__content__item__text">
              The King and I 1956
            </div>
          </div>
          <div className="restoration__content__item">
            <Img
              fluid={data.restorationImage3.childImageSharp.fluid}
              alt="Olympia"
            />
            <div className="restoration__content__item__text">Olympia 1938</div>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default Slick
